import { v4 as uuidv4 } from 'uuid'

function calculateBoundingBox (points) {
  let minX = Infinity
  let minY = Infinity
  let maxX = -Infinity
  let maxY = -Infinity

  for (const [x, y] of points) {
    if (x < minX) minX = x
    if (y < minY) minY = y
    if (x > maxX) maxX = x
    if (y > maxY) maxY = y
  }

  return [[minX, minY], [maxX, maxY]]
}

export function calculateCenter (points) {
  const boundingBox = calculateBoundingBox(points)

  let centerX = (boundingBox[0][0] + boundingBox[1][0]) / 2
  let centerY = (boundingBox[0][1] + boundingBox[1][1]) / 2

  if (!Number.isFinite(centerX)) {
    centerX = 0
  }
  if (!Number.isFinite(centerY)) {
    centerY = 0
  }

  return [centerX, centerY]
}

export function flattenPoints (points) {
  if (points.length === 0) {
    return []
  }

  if (typeof points[0] === 'number') {
    return [points]
  }

  const flattend = []
  for (const point of points) {
    if (typeof point[0] === 'number') {
      flattend.push(point)
    } else {
      for (const realPoint of flattenPoints(point)) {
        flattend.push(realPoint)
      }
    }
  }
  return flattend
}

export function getId (id) {
  if (id !== null) {
    return id
  }
  return uuidv4()
}
