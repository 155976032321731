export default class GeoJSONAutoHandler {
  constructor (builder) {
    this.builder = builder
  }

  supports (data) {
    return typeof data === 'object' && 'type' in data && data.type === 'FeatureCollection'
  }

  handle (data, id = null) {
    this.builder.withGeoJSON(data, id)
  }
}
