import * as R from 'ramda'

export function selectDataNetwork (newValue, oldValue) {
  let filteredValue

  if (newValue.includes('ethernet') && !oldValue.includes('ethernet')) {
    filteredValue = ['ethernet']
  } else {
    filteredValue = newValue

    if (oldValue.includes('ethernet') && filteredValue.length > 1) {
      filteredValue = R.without(['ethernet'], newValue)
    }
    if (filteredValue.includes('mk') && !filteredValue.includes('ftu')) {
      filteredValue = R.append('ftu', filteredValue)
    }
  }
  return filteredValue
}

export function checkMac (mac) {
  return R.isNil(mac) || /^[a-f0-9]{12}$/ig.test(mac)
}
