<template>
  <p
    v-for="(paragraph, index) in paragraphs"
    :key="index"
  >
    <template
      v-for="section in paragraph"
      v-if="Array.isArray(paragraph)"
      :key="`paragraph${section.start}`"
    >
      <template v-if="section.isLink">
        <a
          :href="section.href"
          :target="section.type === 'url' ? '_blank' : '_self'"
        >
          {{ section.value }}
        </a>
      </template>
      <template v-else>
        {{ section.value }}
      </template>
    </template>
    <template v-else>
      {{ paragraph }}
    </template>
  </p>
</template>
<script>
import { splitByLink } from '@/js/task.js'

export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    linkified: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    paragraphs () {
      return this.text
        .split('\n')
        .map(paragraph => {
          if (paragraph.length === 0) {
            return '\xa0'
          } else if (this.linkified) {
            return splitByLink(paragraph)
          } else {
            return paragraph
          }
        })
    }
  }
}
</script>
