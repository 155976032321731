<template>
  <div class="EntityCardList">
    <CardGroup :class="{'c-cards-small': small}">
      <EntityCard
        v-for="(item, index) in items"
        v-if="placeholder === 0"
        :key="'loaded_'+index"
        :color="item.color"
        :href="item.href"
      >
        <template
          v-if="true"
          #header
        >
          <EntityLink
            as="span"
            v-bind="item.link"
          />
        </template>
      </EntityCard>
      <EntityCard
        v-for="placeholderIndex in Array(placeholder)"
        v-else
        :key="'placeholder_'+placeholderIndex"
        :small="small"
        placeholder
      />
    </CardGroup>
  </div>
</template>
<script>
import EntityCard from '@/components/_partials/Molecule/EntityCard.ce.vue'
import { CardGroup } from 'vue-fomantic-ui'
import EntityLink from '@/stories/molecule/EntityLink.ce.vue'

export default {
  tag: 'entity-card-list',
  components: { EntityLink, EntityCard, CardGroup },
  props: {
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: Number,
      default: 0
    },
    small: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>
