<template>
  <EntityLink
    label="WE"
    :reference="unit.home_id"
    :icon="{background: unit.verified ? 'teal' : 'grey', name: unit.verified ? 'check' : 'question'}"
    :attr="{href: unitUrl}"
  />
</template>
<script>
import EntityLink from '@/stories/molecule/EntityLink.ce.vue'

export default {
  components: { EntityLink },
  props: {
    unit: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    unitUrl () {
      return '/unit/detail/' + this.unit.id
    }
  }
}
</script>
