<template>
  <div>
    <SuiMessage
      v-if="error"
      negative
      :content="error"
    />
    <LabeledDropdown
      v-model="camera"
      label="Kamera"
      :options="availableCameras"
      @update:model-value="cameraSelected"
    />
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import QrScanner from 'qr-scanner'
import { SuiMessage } from 'vue-fomantic-ui'
import LabeledDropdown from '@/stories/atom/LabeledDropdown.vue'

const emit = defineEmits(['cameraSelected'])

const availableCameras = ref([])
const camera = ref(null)
const error = ref(null)

function cameraSelected (camera) {
  emit('cameraSelected', camera.id)
}

onMounted(async () => {
  QrScanner.listCameras()
    .then((cameras) => {
      cameras.forEach((camera) => {
        availableCameras.value.push({ id: camera.id, text: camera.label })
      })
      camera.value = availableCameras.value[0]
      cameraSelected(availableCameras.value[0])
    }).catch(() => {
      error.value = 'Es gab einen Fehler beim Ermitteln der verfügbaren Kameras'
    })
})
</script>
