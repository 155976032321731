import { Feature } from 'ol'

export default class FeaturesAutoHandler {
  constructor (builder) {
    this.builder = builder
  }

  supports (data) {
    if (!(data instanceof Array)) {
      return false
    }

    for (const item of data) {
      if (!(item instanceof Feature)) {
        return false
      }
    }

    return true
  }

  handle (data, id = null) {
    this.builder.withFeatures(data, id)
  }
}
