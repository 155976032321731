import 'ol/ol.css'
import 'ol-ext/dist/ol-ext.css'
import FontSymbol from 'ol-ext/style/FontSymbol.js'
import FontUtil from '@/js/utils/fonts'
import TileLayer from 'ol/layer/Tile.js'
import { OSM, Vector as VectorSource, XYZ } from 'ol/source.js'
import GeoJSON from 'ol/format/GeoJSON.js'
import { Vector as VectorLayer } from 'ol/layer.js'
import View from 'ol/View.js'
import { defaults } from 'ol/control.js'
import Map from 'ol/Map.js'
import LayerAutoHandler from '@/js/map/autohandler/LayerAutoHandler'
import GeoJSONAutoHandler from '@/js/map/autohandler/GeoJSONAutoHandler'
import FeaturesAutoHandler from '@/js/map/autohandler/FeaturesAutoHandler'
import FeatureAutoHandler from '@/js/map/autohandler/FeatureAutoHandler'
import StyleMatcherBuilder from '@/js/map/StyleMatcherBuilder'
import StyleHandlerBuilder from '@/js/map/StyleHandlerBuilder'
import { calculateCenter, flattenPoints, getId } from '@/js/map/util.js'

export default class MapBuilder {
  formatHandlers = []
  styleHandlers = []
  finalMapOptions = null
  doneLayer = null
  doneMap = null
  layers = {}
  geojsons = {}
  features = {}
  view = {}
  controls = {}
  projection = 'EPSG:3857'
  target = null

  constructor (formatHandlers = null) {
    if (formatHandlers === null) {
      formatHandlers = [
        new LayerAutoHandler(this),
        new GeoJSONAutoHandler(this),
        new FeaturesAutoHandler(this),
        new FeatureAutoHandler(this),
      ]
    }

    this.formatHandlers = formatHandlers
  }

  static async registerIcons () {
    const fonts = FontUtil.getIconFonts()

    for (const [fontFamily, glyphs] of Object.entries(fonts)) {
      if (!(fontFamily in FontSymbol.defs.fonts)) {
        await FontUtil.untilLoaded(fontFamily)
        const mapped = {}
        for (const key in glyphs) {
          mapped['icon ' + key] = glyphs[key]
        }

        FontSymbol.addDefs({
          font: fontFamily,
          prefix: fontFamily
        }, mapped)
      }
    }
  }

  all () {
    return new StyleMatcherBuilder(this)
  }

  style () {
    return new StyleHandlerBuilder()
  }

  withTileLayer (layer, id = 'tile') {
    this.assertNotBuild()
    this.withLayer(new TileLayer(layer), id)
    return this
  }

  withTileURL (url, options = {}) {
    this.assertNotBuild()
    this.withTileLayer({
      source: new XYZ({
        ...{
          url,
          attributions: [
            '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          ],
          attributionsCollapsible: false
        },
        ...options
      })
    })
    return this
  }

  createFeatureLayer (features, id) {
    return new VectorLayer({
      source: new VectorSource({
        features
      }),
      style: (feature) => this.mapStyle(id, feature)
    })
  }

  withOSM () {
    this.assertNotBuild()
    this.withTileLayer({
      source: new OSM()
    })
  }

  withControls (controls) {
    this.assertNotBuild()
    this.controls = {
      ...this.controls,
      ...controls,
    }
  }

  assertNotBuild () {
    if (this.finalMapOptions !== null) {
      throw new Error('map already build')
    }
  }

  withTarget (target) {
    this.assertNotBuild()
    this.target = target
  }

  withAutoLayer (layer, id = null) {
    for (const handler of this.formatHandlers) {
      if (handler.supports(layer)) {
        handler.handle(layer, id)
        return
      }
    }

    throw new Error('unsupported format')
  }

  withLayer (layer, id = null) {
    this.assertNotBuild()
    id = getId(id)
    this.layers[id] = layer
    return this
  }

  withFeatures (features, id = null) {
    this.assertNotBuild()
    id = getId(id)
    this.features[id] = features
    return this
  }

  withFeature (feature, id = null) {
    this.assertNotBuild()
    id = getId(id)
    if (!(id in this.features)) {
      this.features[id] = []
    }
    this.features[id].push(feature)
    return this
  }

  withGeoJSON (geojson, id = null) {
    this.assertNotBuild()
    id = getId(id)
    this.geojsons[id] = geojson
  }

  withProjection (projection) {
    this.assertNotBuild()
    this.projection = projection
  }

  withView (view) {
    this.assertNotBuild()
    this.view = {
      ...this.view,
      ...view,
    }
  }

  withZoom (zoom) {
    this.assertNotBuild()
    this.withView({
      zoom
    })
  }

  withCenter (center) {
    this.assertNotBuild()
    this.withView({
      center
    })
  }

  mapStyle (id, feature) {
    for (const handler of this.styleHandlers) {
      const style = handler(id, feature, this.doneMap)
      if (style) {
        return style
      }
    }

    return this.style().build()
  }

  withStyleHandler (handler) {
    this.assertNotBuild()
    this.styleHandlers.push(handler)
  }

  buildFeatureLayers () {
    this.assertNotBuild()
    const result = {}

    for (const [key, features] of Object.entries(this.features)) {
      result[key] = this.createFeatureLayer(features, key)
    }

    return result
  }

  buildGeojsonLayers () {
    this.assertNotBuild()
    const convert = (geojson, key) => {
      const options = {}

      if (
        'crs' in geojson &&
        'type' in geojson.crs &&
        'properties' in geojson.crs &&
        'name' in geojson.crs.properties &&
        geojson.crs.type === 'name'
      ) {
        options.featureProjection = this.projection
        options.dataProjection = geojson.crs.properties.name
      }

      return this.createFeatureLayer(new GeoJSON(options).readFeatures(geojson), key)
    }

    const result = {}

    for (const [key, value] of Object.entries(this.geojsons)) {
      result[key] = convert(value, key)
    }

    return result
  }

  buildLayers () {
    this.assertNotBuild()

    if (this.doneLayer === null) {
      let layers = this.layers

      if (Object.keys(this.features).length > 0) {
        layers = { ...layers, ...this.buildFeatureLayers() }
      }

      if (Object.keys(this.geojsons).length > 0) {
        layers = { ...layers, ...this.buildGeojsonLayers() }
      }

      this.doneLayer = layers
    }

    return this.doneLayer
  }

  getFeatures () {
    if (this.doneLayer === null) {
      throw new Error('not build')
    }
    const features = []
    const layers = this.doneLayer

    for (const layer of Object.values(layers)) {
      if (layer instanceof VectorLayer) {
        for (const feature of layer.getSource().getFeatures()) {
          features.push(feature)
        }
      }
    }

    return features
  }

  getFeaturePoints () {
    let points = []

    for (const feature of this.getFeatures()) {
      let geometries = [feature.getGeometry()]
      if ('getGeometries' in geometries[0]) {
        geometries = geometries[0].getGeometries()
      }

      for (const geometry of geometries) {
        const flattend = flattenPoints(geometry.getCoordinates())
        points = points.concat(flattend)
      }
    }

    return points
  }

  getLayers () {
    if (this.doneLayer === null) {
      throw new Error('not build')
    }

    return this.doneLayer
  }

  getLayer (id) {
    if (this.doneLayer === null || !(id in this.doneLayer)) {
      throw new Error('not build')
    }
    return this.doneLayer[id]
  }

  buildView () {
    this.buildLayers()
    this.assertNotBuild()
    const options = {
      ...this.view,
      ...{
        projection: this.projection
      }
    }

    if (!('zoom' in options)) {
      options.zoom = 12
    }

    if (!('center' in options)) {
      options.center = calculateCenter(
        this.getFeaturePoints()
      )
    }

    return new View(options)
  }

  async build () {
    this.assertNotBuild()
    await MapBuilder.registerIcons()

    const options = {
      layers: Object.values(this.buildLayers()),
      view: this.buildView(),
      controls: defaults(this.controls)
    }

    if (this.target !== null) {
      options.target = this.target
    }

    this.finalMapOptions = options

    return options
  }

  async buildMap () {
    this.assertNotBuild()
    this.doneMap = new Map(await this.build())
    return this.doneMap
  }
}
