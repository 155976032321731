<template>
  <BuildingSelection
    init-confirm-text="Gebäude als versorgendes Gebäude auswählen"
    :init-selected-building-id="props.initSelectedBuildingId"
    :can-change-selected-building="true"
    :result-element-name="props.resultElementName"
    :is-disabled="props.isDisabled"
    :init-search-url="searchUrl"
  />
</template>
<script setup>

import BuildingSelection from '@/components/Building/BuildingSelection.vue'
import { ref, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  initSelectedBuildingId: {
    type: Number,
    required: true,
  },
  excavationSelectId: {
    type: String,
    required: true
  },
  resultElementName: {
    type: String,
    required: true
  },
  isDisabled: {
    type: Boolean,
    required: true
  }
})

const selectedOption = ref('')
const searchUrl = ref('')

const syncSelectedOption = (value) => {
  selectedOption.value = value

  let type = 'ftth'

  if (selectedOption.value === 'b2b_supplied') {
    type = 'b2b'
  }

  searchUrl.value = '/api/v1/building/search-supplied-by/' + type + '/'
}

onMounted(() => {
  const externalSelect = document.getElementById(props.excavationSelectId)
  syncSelectedOption(externalSelect.value)

  if (externalSelect) {
    externalSelect.addEventListener('change', (event) => {
      syncSelectedOption(event.target.value)
    })
  }
})

onBeforeUnmount(() => {
  const externalSelect = document.getElementById(props.excavationSelectId)
  if (externalSelect) {
    externalSelect.removeEventListener('change', (event) => {
      syncSelectedOption(event.target.value)
    })
  }
})
</script>
