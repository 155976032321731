<template>
  <div class="ImageCropper">
    <div
      ref="croppieContainer"
      class="ImageCropper-preview"
      :style="previewStyle"
    >
      <div ref="croppie" />
    </div>
    <SuiSlider
      v-if="loaded && zoom !== null"
      v-model="zoom"
      :min="minZoom"
      :max="maxZoom"
    />
    <input
      :id="fileInputId"
      type="file"
      class="ui invisible file input"
      :accept="accept.join(', ')"
      @change="(e) => load(e)"
    >
    <SuiButtonGroup>
      <label
        :for="fileInputId"
        class="ui icon primary button"
      >
        <i class="file image icon" />
        Bild auswählen
      </label>
      <slot name="buttons" />
    </SuiButtonGroup>
  </div>
</template>

<script>
import { SuiSlider, SuiButtonGroup } from 'vue-fomantic-ui'
import * as PromiseFileReader from 'promise-file-reader'
import Croppie from 'croppie'
import 'croppie/croppie.css'

const waitUpdate = 250
const sliderStep = 1000
let id = 0

export default {
  tag: 'image-cropper',
  components: {
    SuiSlider,
    SuiButtonGroup
  },
  props: {
    size: {
      type: Number,
      default: 300
    },
    type: {
      type: String,
      default: 'file'
    },
    format: {
      type: String,
      default: null
    },
    accept: {
      type: Array,
      default: () => [
        'image/svg',
        'image/png',
        'image/gif',
        'image/jpeg',
        'image/webp',
        'image/tiff',
        'image/bmp'
      ]
    },
    quality: {
      type: Number,
      default: 0.92
    }
  },
  data: () => ({
    loaded: false,
    zoom: 0,
    id: 0,
    minZoom: 0,
    maxZoom: 0,
    filename: null,
    croppie: null,
    interval: null,
    timeout: null
  }),
  computed: {
    previewStyle () {
      if (!this.loaded) {
        return {
          display: 'none'
        }
      }

      return {
        width: this.size + 'px',
        height: this.size + 'px'
      }
    },
    supported () {
      return typeof FileReader !== 'undefined'
    },
    fileInputId () {
      return 'ImageCropperFileInput' + this.id
    }
  },
  watch: {
    zoom (value) {
      this.croppie.setZoom(value / sliderStep)
    }
  },
  beforeCreate () {
    this.id = id
    id += 1
  },
  mounted () {
    const el = this.$refs.croppie

    el.addEventListener('update', (ev) => {
      this.update(ev)
    })

    this.croppie = new Croppie(el, {
      enableExif: true,
      mouseWheelZoom: false,
      showZoomer: false,
      viewport: {
        width: this.size,
        height: this.size,
        type: 'circle'
      }
    })
  },
  methods: {
    mimeType (format) {
      if (typeof format === 'undefined' || format === null) {
        format = 'jpeg'
      }
      if (format.indexOf('/') === -1) {
        format = 'image/' + format
      }

      if (format === 'image/jpg') {
        format = 'image/jpeg'
      }

      return format
    },
    ensureFilenameExtension (filename, format) {
      const mimeType = this.mimeType(format)
      const parts = mimeType.split('/')

      if (parts.length !== 2) {
        return null
      }

      let extension = parts[1].toLowerCase()

      if (extension === 'jpeg') {
        extension = 'jpg'
      } else if (extension.includes('tiff')) {
        extension = 'tiff'
      }

      const baseName = filename.replace(/\.[^/.]+$/, '')

      return `${baseName}.${extension}`
    },
    convertToFormat (canvas, format = 'jpeg', quality = 0.92) {
      const mimeType = this.mimeType(format)

      return new Promise((resolve, reject) => {
        // Get the data URI of the canvas with the desired format
        try {
          if (canvas.width === 0 || canvas.height === 0) {
            return reject(new Error('Canvas is empty'))
          }

          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob)
            } else {
              reject(new Error('Canvas conversion failed.'))
            }
          }, mimeType, quality)
        } catch (error) {
          reject(error)
        }
      })
    },
    convertToType (blob, filename, format, type) {
      filename = this.ensureFilenameExtension(filename, format)
      const mimeType = this.mimeType(format)

      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        switch (type) {
          case 'base64':
            reader.onloadend = () => {
              resolve(reader.result)
            }

            reader.onerror = (error) => {
              reject(new Error('Error converting Blob to Data URI: ' + error))
            }

            reader.readAsDataURL(blob)
            break
          case 'file':
            resolve(new File([blob], filename, {
              type: mimeType
            }))
            break
          case 'canvas':
          default:
            resolve(blob)
        }
      })
    },
    result () {
      this.croppie.result({ type: 'rawcanvas', circle: false })
        .then(
          data => this.type === 'canvas' ? Promise.resolve(data) : this.convertToFormat(data, this.format, this.quality)
        )
        .then(
          data => data === null
            ? Promise.resolve(data)
            : this.convertToType(
                data,
                this.filename,
                this.format,
                this.type
              )
        )
        .then(
          (data) => {
            if (data !== null) {
              this.$emit('result', data)
            }
          }
        )
        .catch(
          (e) => this.$emit('error', e)
        )
    },
    update () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        clearTimeout(this.timeout)
        this.result()
      }, waitUpdate)
    },
    updateZoom () {
      if (this.croppie.elements.zoomer.max) {
        clearInterval(this.interval)
        this.zoom = parseFloat(this.croppie.elements.zoomer.value) * sliderStep
        this.minZoom = parseFloat(this.croppie.elements.zoomer.min) * sliderStep
        this.maxZoom = parseFloat(this.croppie.elements.zoomer.max) * sliderStep
      } else {
        this.zoom = 0
        this.minZoom = 0
        this.maxZoom = 0
      }
    },
    load (evt) {
      const tgt = evt.target
      const files = tgt.files

      if (!files || files.length === 0) {
        this.backgroundImage = 'none'
        return
      }

      this.filename = files[0].name
      this.$emit('changed', files[0])

      PromiseFileReader
        .readAsDataURL(files[0])
        .then((result) => {
          this.loaded = true
          return this.croppie.bind({
            url: result
          })
        })
        .then(() => {
          this.result()

          this.interval = setInterval(() => {
            this.updateZoom()
          }, 10)
        })
        .catch((e) => {
          if (e instanceof Event) {
            e = new Error('Datei ist kein Bild')
          }
          this.$emit('error', e)
        })
    }
  }
}
</script>
