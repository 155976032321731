import $ from 'jquery'
import linkifyHtml from 'linkify-html'
import * as linkifyJs from 'linkifyjs'
import { fenster, isEmpty } from '@/js/project'

/* istanbul ignore next */
$(function () {
  toggleAnswers()
  addLinkedTasks()
  linkify()
  openTaskWindow()
  for (const taskType of ['item', 'task']) {
    initializeShortcuts(taskType)
  }
  toggleShowPendingTaskWaitUntil()
})

/**
 * toggle between show and hide answers.
 */
export function toggleAnswers () {
  $('#answers-show, #answers-hide').on('click', function (e) {
    const parent = $(this).parents('.aufgabe-entity')
    parent.find('.answers-click').toggle('display')
    parent.find('.answers').toggle('display')

    e.stopPropagation()
  })
}

/**
 * Shows an inactive element or hide it, set the toggle button to enabled or disabled.
 *
 * @param {string} parentClass - the parent class name where the elements should be toggled
 * @param {string} className - the class name of the element to show or hide
 * @param {string} toggle - the toggle button
 */
export function toggleShow (parentClass, className, toggle) {
  $(toggle).toggleClass(['active', 'no-active'])
  const element = $(parentClass).find(className)

  const activeTabId = $('.a-inline-center.active').attr('id')
  if (activeTabId) {
    const type = activeTabId.substring(7)
    if (type === 'all') {
      element.toggle('display')
    } else {
      element.filter('.' + type).toggle('display')
    }
  } else {
    element.toggle('display')
  }
}

export function linkify () {
  const options = {
    defaultProtocol: 'https',
    target: { url: '_blank', email: null, hashtag: null },
    validate: { url: (value) => /^https?:\/\//.test(value) }
  }
  $('.linkify').html(function (index, text) {
    return linkifyHtml(text, options)
  })
}

export function splitByLink (text) {
  function createText (text, start, end) {
    return {
      value: text.slice(start, end),
      isLink: false,
      start
    }
  }

  const links = linkifyJs.find(text, null, {
    defaultProtocol: 'https',
  })

  let textStartPosition = 0
  const accumulator = []
  for (const link of links) {
    if (link.start !== textStartPosition) {
      accumulator.push(createText(text, textStartPosition, link.start))
    }
    accumulator.push(link)
    textStartPosition = link.end
  }
  if (textStartPosition < text.length) {
    accumulator.push(createText(text, textStartPosition))
  }
  return accumulator
}

export function addLinkedTasks () {
  const linkedTasks = document.getElementById('task_linkedTasks')
  if (linkedTasks === null) {
    return
  }

  $('#add-linked-task').on('click', function (e) {
    const index = parseInt(linkedTasks.dataset.index)
    const prototype = linkedTasks.dataset
      .prototype
      .replace(/__name__/g, index)
      .replace('hidden', 'text')

    $(prototype).insertBefore(e.currentTarget)

    linkedTasks.dataset.index = index + 1
  })

  $('.delete-task-item').click('click', function (e) {
    const taskInput = linkedTasks.querySelector(`input[value="${e.currentTarget.dataset.taskId}"]`)

    $(e.currentTarget).parents('li').remove()
    linkedTasks.removeChild(taskInput)
    return false
  })
}

/**
 * @param {string} taskType - task or item
 */
export function initializeShortcuts (taskType) {
  $('.task-shortcut').each(function (_index, shortcut) {
    shortcut.addEventListener('click', function (event) {
      $(`textarea[name^="${taskType}[comment]"]`)
        .val(replaceDescriptionDynamicVariables(shortcut)) // Unescapes the string

      $(`select[name="${taskType}[status]"]`).val(shortcut.dataset.status)

      if (globalThis.topicApp && shortcut.dataset.topic) {
        const selectTopicEvent = new CustomEvent('set-selection', {
          detail: {
            topicId: parseInt(shortcut.dataset.topic),
            subtopicId: shortcut.dataset.subtopic ? parseInt(shortcut.dataset.subtopic) : undefined
          }
        })
        globalThis.topicApp.config.globalProperties.refs.root.dispatchEvent(selectTopicEvent)
      }
      let id = null

      if (shortcut.dataset.user) {
        id = `u-${shortcut.dataset.user}`
      } else if (shortcut.dataset.group) {
        id = `g-${shortcut.dataset.group}`
      }

      if (id !== null) {
        $(`select[name="${taskType}[party][party]"] option[id="${id}"]`)
          .prop('selected', true)
          .trigger('change')
      }
      event.preventDefault()
    })
  })
}

function replaceDescriptionDynamicVariables (shortcut) {
  const variableMap = {
    '$PROJECT-NUMBER': $('#project-number').val(),
    '$PROJECT-NAME': $('#project-name').val()
  }

  let description = shortcut.dataset.description

  for (const variable in variableMap) {
    if (isEmpty(variableMap[variable])) {
      description = description.replaceAll(variable, 'XXXX-XXX')
    } else {
      description = description.replaceAll(variable, variableMap[variable])
    }
  }

  return description
}

export function openTaskWindow () {
  const parsedUrl = new URL(window.location.href)
  const taskId = parsedUrl.searchParams.get('aufgabe')
  const projectId = parsedUrl.searchParams.get('projectId')

  if (!document.getElementById('task-window') || taskId === null) {
    return
  }

  let windowUrl

  if (projectId !== '0' && projectId !== '' && projectId !== null) {
    windowUrl = '/aufgabe/' + taskId + '?auftrag=' + projectId
  } else {
    windowUrl = '/aufgabe/' + taskId + '?gebaeude=' + parsedUrl.searchParams.get('building') +
        '&person=' + parsedUrl.searchParams.get('person')
  }

  if (windowUrl) {
    fenster(windowUrl, 'Aufgabe', 1040, 900)
  }
}

export function toggleShowPendingTaskWaitUntil () {
  function togglePendingTaskDate () {
    if ($('#item_status').val() !== 'due_date' && $('#task_status').val() !== 'due_date') {
      $('#pendingTaskStatus').hide()
    } else {
      $('#pendingTaskStatus').show()
    }
  }

  // Call the function on page load
  togglePendingTaskDate()
  $('#item_status, #task_status').on('change', togglePendingTaskDate)
}
globalThis.toggleShow = toggleShow
