import RobotoRegular from '@/fonts/Roboto-Regular.woff2'
import RobotoBold from '@/fonts/Roboto-Bold.woff2'
import RobotoMono from '@/fonts/RobotoMono-Bold.woff2'
import Icons from 'fomantic-ui-less/themes/default/assets/fonts/icons.woff2'
import OutlineIcons from 'fomantic-ui-less/themes/default/assets/fonts/outline-icons.woff2'
import BrandIcons from 'fomantic-ui-less/themes/default/assets/fonts/brand-icons.woff2'
import FomanticIconCSS from '!!raw-loader!fomantic-ui-less/themes/default/elements/icon.variables'

export class IconMap {
  css = null
  _Fonts = null
  _Symbols = null
  static _instance = null

  constructor (css) {
    this.css = css
  }

  static getInstance () {
    if (this._instance === null) {
      this._instance = new IconMap(FomanticIconCSS)
    }

    return IconMap._instance
  }

  get Fonts () {
    this.compute()
    return this._Fonts
  }

  get Symbols () {
    this.compute()
    return this._Symbols
  }

  compute () {
    if (this._Fonts !== null && this._Symbols !== null) {
      return
    }

    this._Symbols = {}
    this._Fonts = {}
    const regex = /@([a-zA-Z0-9_-]+):\s*{([^}]+)}/g
    let match

    const mappedFont = {
      'icon-outline-map': 'outline-icons',
      'icon-outline-aliases-map': 'outline-icons',
      'icon-thin-map': 'thin-icons',
      'icon-thin-aliases-map': 'thin-icons',
      'icon-brand-map': 'brand-icons',
      'icon-brand-aliases-map': 'brand-icons',
      'icon-duotone-map': 'duotone-icons',
      'icon-duotone-secondary-map': 'duotone-icons',
      'icon-duotone-aliases-map': 'duotone-icons',
      'icon-duotone-secondary-aliases-map': 'duotone-icons'
    }

    while ((match = regex.exec(this.css)) !== null) {
      const key = match[1]
      if (!key.startsWith('icon-')) {
        continue
      }

      this._Fonts[key] = key in mappedFont ? mappedFont[key] : 'icons'

      this._Symbols[key] = match[2]
        .split(';')
        .filter(line => line.trim())
        .reduce((acc, line) => {
          let [iconKey, iconValue] = line.split(':').map(str => str.trim())
          iconValue = iconValue.replace(/"/g, '').replace(/^\\/, '')
          iconKey = iconKey.replace(/_/g, ' ')
          acc[iconKey] = String.fromCharCode(parseInt(iconValue, 16))
          return acc
        }, {})
    }
  }
}

const FontList = [
  {
    family: 'icons',
    url: Icons,
    style: 'normal',
    weight: 700,
    display: 'block',
    variant: 'normal',
    decoration: 'inherit',
    transform: 'none'
  },
  {
    family: 'outline-icons',
    url: OutlineIcons,
    style: 'normal',
    weight: 700,
    display: 'block',
    variant: 'normal',
    decoration: 'inherit',
    transform: 'none'
  },
  {
    family: 'brand-icons',
    url: BrandIcons,
    style: 'normal',
    weight: 700,
    display: 'block',
    variant: 'normal',
    decoration: 'inherit',
    transform: 'none'
  },
  {
    family: 'Roboto Mono',
    url: RobotoMono,
    style: 'normal',
    weight: 700,
    display: 'auto'
  },
  {
    family: 'Roboto',
    url: RobotoRegular,
    style: 'normal',
    weight: 400
  },
  {
    family: 'Roboto',
    url: RobotoBold,
    style: 'normal',
    weight: 700
  }
]

function filterByFamily (family) {
  const filtered = []

  for (const data of FontList) {
    if (data.family === family) {
      filtered.push(data)
    }
  }

  return filtered
}

export default class FontUtil {
  static _instance = null
  loaded = {}
  iconMap = null

  constructor (iconMap) {
    this.iconMap = iconMap
  }

  static getInstance () {
    if (this._instance === null) {
      this._instance = new FontUtil(
        IconMap.getInstance()
      )
    }

    return FontUtil._instance
  }

  _getIconFonts () {
    const IconMapFonts = this.iconMap.Fonts
    const IconMapSymbols = this.iconMap.Symbols

    const fonts = {}
    for (const [key, value] of Object.entries(IconMapSymbols)) {
      const family = filterByFamily(IconMapFonts[key])
      if (family.length === 0) {
        continue
      }
      if (!(family[0].family in fonts)) {
        fonts[family[0].family] = {}
      }

      fonts[family[0].family] = {
        ...fonts[family[0].family],
        ...value
      }
    }

    return fonts
  }

  static getIconFonts () {
    return FontUtil.getInstance()._getIconFonts()
  }

  async _addFont (data) {
    if ('FontFace' in window) {
      const options = {
        ...data
      }
      delete options['family']
      delete options['url']
      const font = new FontFace(data.family, 'url(' + data.url + ')', options)
      document.fonts.add(font)
      await font.load()
    }
  }

  static addFont (data) {
    return FontUtil.getInstance()._addFont()
  }

  async _untilLoaded (name) {
    if (name in this.loaded && this.loaded[name]) {
      return
    }

    const fonts = filterByFamily(name)
    for (const data of fonts) {
      await this._addFont(data)
    }

    this.loaded[name] = true
  }

  static async untilLoaded (name) {
    return FontUtil.getInstance()._untilLoaded(name)
  }
}
