import StyleHandlerBuilder from '@/js/map/StyleHandlerBuilder'

export default class StyleMatcherBuilder {
  builder = null
  matchesId = null
  propertyMatches = {}
  checks = []

  constructor (builder) {
    this.builder = builder
  }

  withPropertyMatches (key, regex) {
    this.propertyMatches[key] = regex
    return this
  }

  withMatches (check) {
    this.checks.push(check)
    return this
  }

  withLayer (id) {
    this.matchesId = id
    return this
  }

  check (id, data, map) {
    const values = data.values_

    for (const check of this.checks) {
      if (!(check({
        id,
        data,
        values,
        map
      }))) {
        return false
      }
    }

    for (const [property, matcher] of Object.entries(this.propertyMatches)) {
      if (!(property in values)) {
        return false
      }

      const value = values[property]
      if (matcher instanceof RegExp) {
        if (!matcher.exec(value)) {
          return false
        }
      } else if (value !== matcher) {
        return false
      }
    }

    return !(this.matchesId !== null && this.matchesId !== id)
  }

  apply (callback) {
    this.builder.withStyleHandler((id, data, map) => {
      if (!this.check(id, data, map)) {
        return null
      }

      let result = callback({
        id,
        data,
        map,
        values: data.values_
      })

      if (result instanceof StyleHandlerBuilder) {
        result = result.build()
      }

      return result
    })
  }
}
