import { Layer } from 'ol/layer.js'

export default class LayerAutoHandler {
  constructor (builder) {
    this.builder = builder
  }

  supports (data) {
    return data instanceof Layer
  }

  handle (data, id = null) {
    this.builder.withLayer(data, id)
  }
}
