<template>
  <div>
    <SuiButton
      primary
      class="m-0"
      @click="openWizard"
    >
      Verifikation starten
    </SuiButton>
    <HomeIdDialog
      v-model="model"
      :home-id-text="homeIdText"
      :unit-location-in-building="unitLocationInBuilding"
      :unit-tv="unitTv"
      :unit-data="unitData"
      :unit-mac="unitMac"
      :home-id-qr="homeIdText"
      :disabled="disabled"
      :preview="printImage"
      :qr-location="location"
      @connect="onConnect"
      @complete="onComplete"
      @print="onPrint"
      @submit="onSubmit"
    />
  </div>
</template>

<script setup>
import HomeIdDialog from '@/stories/templates/HomeIdDialog'
import HomeIdPrint from '@/js/homeId'
import { SuiButton } from 'vue-fomantic-ui'
import { ref, computed } from 'vue'
import { requestPromise } from '@/js/request.js'
const homeIdPrint = new HomeIdPrint()

const model = defineModel({
  type: Object,
  default: {
    show: (new URLSearchParams(window.location.search)).has('wizard')
  }
})
const props = defineProps({
  unitId: {
    type: Number,
    required: true
  },
  homeIdText: {
    type: String,
    required: true
  },
  unitLocationInBuilding: {
    type: String,
    required: true
  },
  unitTv: {
    type: String,
    default: null
  },
  unitData: {
    type: Array,
    default: () => []
  },
  unitMac: {
    type: String,
    default: null
  }
})

const printImage = ref(null)

const location = computed(() => {
  const qrTarget = new URL(window.location)
  qrTarget.searchParams.set('wizard', '')
  return qrTarget.toString()
})

const disabled = computed(() => {
  if (!homeIdPrint.isSupportedBrowser()) {
    return 'Ihr Browser unterstützt die Verbindung zum Drucker nicht.'
  }

  return null
})

homeIdPrint.createQR(props.homeIdText).then((url) => {
  printImage.value = url
}).catch((e) => {
  printImage.value = new Error(e)
})

function update (e) {
  model.value = {
    ...model.value,
    ...e
  }
}

function openWizard () {
  update({
    show: true
  })
}

function onConnect () {
  return homeIdPrint.connect()
}

function onComplete () {
  return new Promise(() => {
    window.location.reload()
  })
}

function onPrint () {
  if (typeof printImage.value !== 'string') {
    return Promise.reject(new Error('Label konnte nicht generiert werden'))
  }

  return homeIdPrint.print(printImage.value)
}

function onSubmit (data) {
  const result = data.image.picture.match(/^data:image\/(.+);base64,(.*)$/)

  if (result === null) {
    return Promise.reject(new Error('invalid image data'))
  }

  return requestPromise('POST', '/api/v1/unit/' + props.unitId + '/verify', {
    location_in_building: data.location,
    home_id_photo: result[2],
    filename: 'homeid.' + result[1],
    extension: result[1],
    tv_network: data.tv,
    mac: data.mac,
    mk: data.data.includes('mk'),
    ftu: data.data.includes('ftu'),
    ethernet: data.data.includes('ethernet'),
    gf_ta: data.data.includes('gfTa')
  })
}
</script>
