<template>
  <div class="ui form">
    <div class="field">
      <label>
        {{ props.label }}
        <sui-dropdown
          v-model="model"
          :options="options"
          selection
          @update:model-value="valueSelected"
        />
      </label>
    </div>
  </div>
</template>
<script setup>
import { SuiDropdown } from 'vue-fomantic-ui'

const emit = defineEmits(['update:model-value'])

const model = defineModel({
  type: Object,
  default: null
})

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  options: {
    type: Array,
    required: true
  }
})

function valueSelected (newSelection) {
  emit('update:model-value', newSelection)
}
</script>
