<template>
  <div>
    <ModalMessage
      v-if="disabled !== null && disabled.length !== 0"
      :model-value="model.show"
      :header-label="`WE-Verifikation ${props.homeIdText}`"
      @update:model-value="(value) => setShow(value)"
    >
      <template #content>
        <p>
          Die Verifikation von Wohneinheiten erfordert das Abscannen eines QR-Codes und ist
          vornehmlich für Mobilgeräte (Tablets, Smartphones) konzipiert.
        </p>
        <p v-if="disabled.length === 0">
          Ihr Browser wird nicht unterstützt.
        </p>
        <p v-else>
          {{ disabled }}
        </p>
        <p>
          Bitte scannen Sie folgenden QR-Code mit ihrem Mobilgerät, um die Verifikation
          dort zu starten.
        </p>
        <section class="ModalMessage-qr-code">
          <img
            v-if="typeof qrCodeImage === 'string'"
            :src="qrCodeImage"
            alt=""
          >
          <div
            v-else
            class="ui placeholder"
          >
            <div class="image" />
          </div>
        </section>
      </template>
    </ModalMessage>
    <ModalWizard
      v-else
      v-model="model"
      :steps="steps"
      :next-disabled="nextDisabled"
      :previous-disabled="true"
      @next="onNext"
      @complete="onComplete"
    >
      <template #start>
        <p>Klicken Sie auf ‚Weiter‘, um den Drucker zu verbinden und die Berechtigung auf die Kamera zu erteilen.</p>
      </template>
      <template #connect>
        <PermissionOverlay
          :permissions="{ camera: true, printer: connectPrinter }"
          :optional="false"
          :retryable="['printer']"
          @granted="onPermissionsGranted"
        >
          <template #default>
            Bei Verbindungsproblemen mit dem Drucker kann es helfen den Drucker zurückzusetzen.
            Dazu die Power-Taste 5 Sekunden lang gedrückt halten.
          </template>
        </PermissionOverlay>
        <p>Bitte den Drucker im Abfragedialog verbinden und den Zugriff auf die Kamera erlauben.</p>
      </template>
      <template #location>
        <HomeIdLocation
          v-model:location="location"
          v-model:data="dataNetwork"
          v-model:tv="tvNetwork"
          :mac="unitMac"
          @mac="value => mac = value"
        />
      </template>
      <template #print>
        <LabelPrinter
          button-text="Label drucken"
          :preview="props.preview"
          :on-print="props.onPrint"
        />
      </template>
      <template #submit>
        <QRSnapshoter
          :validate="onValidateScan"
          :overlay="false"
          @result="(result) => onSnapshotResult(result)"
          @error="onSnapshotError"
        />
      </template>
      <template #success>
        <p>Die Wohneinheit wurde erfolgreich verifiziert. Der Dialog kann nun geschlossen werden.</p>
      </template>
    </ModalWizard>
  </div>
</template>

<script setup>
import ModalWizard from '@/stories/organism/ModalWizard.ce.vue'
import ModalMessage from '@/components/_partials/Molecule/ModalMessage.ce.vue'
import HomeIdLocation from '@/stories/organism/HomeIdLocation.vue'
import { onMounted, ref, watch } from 'vue'
import QRSnapshoter from '@/components/_partials/Molecule/QRSnapshoter.ce.vue'
import PermissionOverlay from '@/components/_partials/Molecule/PermissionOverlay.ce.vue'
import QRCode from 'qrcode'
import LabelPrinter from '@/stories/molecule/LabelPrinter.vue'
import { checkMac } from '@/js/utils/unit-form.js'

const props = defineProps({
  homeIdText: {
    type: String,
    default: ''
  },
  unitLocationInBuilding: {
    type: String,
    default: ''
  },
  unitTv: {
    type: String,
    default: null
  },
  unitData: {
    type: Array,
    default: () => []
  },
  unitMac: {
    type: String,
    default: null
  },
  homeIdQr: {
    type: String,
    default: ''
  },
  disabled: {
    type: String,
    default: null
  },
  preview: {
    type: String,
    default: null
  },
  qrLocation: {
    type: String,
    default: ''
  },
  onConnect: {
    type: Function,
    default: async () => Promise.resolve()
  },
  onPrint: {
    type: Function,
    default: async () => Promise.resolve()
  },
  onSubmit: {
    type: Function,
    default: async () => Promise.resolve()
  },
  onComplete: {
    type: Function,
    default: async () => Promise.resolve()
  }
})

const scan = ref(null)
const nextDisabled = ref(false)
const qrCodeImage = ref(null)

const location = ref(props.unitLocationInBuilding)
const dataNetwork = ref(props.unitData)
const tvNetwork = ref(props.unitTv)
const mac = ref(null)

QRCode.toDataURL(props.qrLocation, {
  width: 250
}).then((url) => {
  qrCodeImage.value = url
}).catch((e) => {
  qrCodeImage.value = new Error(e)
})

const steps = [
  { title: 'Start', icon: 'play', slot: 'start', firstLabel: 'WE-Verifikation', secondLabel: 'Start' },
  { title: 'Drucker & Kamera', icon: 'question', slot: 'connect', firstLabel: 'WE-Verifikation', secondLabel: 'Verbindung & Berechtigung' },
  { title: 'Lage', icon: 'map marker', slot: 'location', firstLabel: 'WE-Verifikation', secondLabel: 'Lage' },
  { title: 'Labels', icon: 'print', slot: 'print', firstLabel: 'WE-Verifikation', secondLabel: 'Labels' },
  { title: 'Foto', icon: 'image', slot: 'submit', firstLabel: 'WE-Verifikation', secondLabel: 'Foto' },
  { title: 'Ende', icon: 'stop', slot: 'success', firstLabel: 'WE-Verifikation', secondLabel: 'Abgeschlossen' }
]

const model = defineModel({
  type: Object,
  default: {
    show: false,
    loading: null,
    error: null,
    step: null
  }
})

function updateModel (data) {
  model.value = {
    ...model.value,
    ...data
  }
}

function onSnapshotError (e) {
  updateModel({
    error: e
  })
}

function updateDisabled (data) {
  if (typeof data === 'undefined') {
    data = model.value
  }

  if (data === null || !('step' in data) || data.step === null || !('slot' in data.step)) {
    nextDisabled.value = false
    return
  }

  switch (data.step.slot) {
    case 'submit':
      nextDisabled.value = scan.value === null
      break
    case 'location':
      nextDisabled.value = typeof location.value !== 'string' || location.value.length === 0 ||
        tvNetwork.value === null || !checkMac(mac.value)
      break
    default:
      nextDisabled.value = false
  }
}

function onSnapshotResult (result) {
  scan.value = result
  updateDisabled()
}

function onValidateScan (result) {
  const messages = {}

  messages['Foto ist gerade'] = result !== null && result.rectangularity > 0.95 && result.straightness > 0.6
  messages['Aufkleber ist in der Mitte des Fotos'] = result !== null && result.centeredness > 0.80
  messages['Beklebtes Gerät ist erkennbar'] = result !== null && result.percentOfImage < 0.02
  messages['Home ID ' + props.homeIdText + ' überprüft'] = result !== null && result.data === props.homeIdQr

  return messages
}

async function onModelChanged (newModalShown) {
  updateDisabled(newModalShown)
}

function onPermissionsGranted () {
  updateModel({
    step: steps[2],
    loading: null
  })
}

onMounted(() => {
  if (model.value.show) {
    onModelChanged(model.value)
  }
})

watch(() => [location.value, tvNetwork.value, dataNetwork.value, mac.value], function (value) {
  updateDisabled(model.value)
})

function setShow (value) {
  updateModel({
    show: value
  })
}

watch(model, onModelChanged)

function onNext (step) {
  const handleStep = (promise) => {
    promise.then(() => {
      step.resolve()
      updateDisabled()
    }).catch((e) => {
      step.reject(e)
    })
  }

  switch (step.step.slot) {
    case 'connect':
      handleStep(props.onConnect())
      break
    case 'location':
      if (location.value.length === 0) {
        step.reject('Bitte Lageangabe korrigieren')
      } else if (!checkMac(mac.value)) {
        step.reject('Bitte eine gültige MAC-Adresse angeben')
      } else {
        step.resolve()
        updateDisabled()
      }
      break
    case 'submit':
      handleStep(props.onSubmit({
        location: location.value,
        data: dataNetwork.value,
        tv: tvNetwork.value,
        mac: mac.value,
        image: scan.value
      }))
      break
    default:
      updateModel({
        step: steps[1],
        loading: null
      })
      step.resolve()
      updateDisabled()
      break
  }
}

function connectPrinter () {
  return props.onConnect()
}
</script>
