<template>
  <section class="LabelPrinter-qr-code-preview">
    <img
      v-if="typeof preview === 'string'"
      :src="preview"
      alt=""
    >
    <div
      v-else
      class="ui placeholder"
    >
      <div class="image" />
    </div>
  </section>
  <div class="ui form">
    <div class="field">
      <label>
        Anzahl
        <input
          v-model="numberOfLabelsToPrint"
          class="ui input"
          type="number"
          min="1"
          :disabled="isCurrentlyPrinting"
        >
      </label>
    </div>
  </div>
  <br>
  <SuiButton
    primary
    :loading="isCurrentlyPrinting"
    @click="printLabels"
  >
    <SuiIcon name="print" />
    <span>Label drucken</span>
  </SuiButton>
  <SuiMessage
    v-if="printError"
    negative
    :content="printError"
  />
</template>
<script setup>
import { SuiButton, SuiIcon, SuiMessage } from 'vue-fomantic-ui'
import { ref } from 'vue'

const props = defineProps({
  onPrint: {
    type: Function,
    default: async () => Promise.resolve()
  },
  buttonText: {
    type: String,
    default: 'Drucken'
  },
  preview: {
    type: String,
    default: null
  }
})

const numberOfLabelsToPrint = ref('2')
const isCurrentlyPrinting = ref(false)
const printError = ref(null)

async function printLabels () {
  isCurrentlyPrinting.value = true

  for (let i = 0; i < numberOfLabelsToPrint.value; i++) {
    try {
      await props.onPrint()
    } catch (error) {
      isCurrentlyPrinting.value = false
      printError.value = 'Beim Drucken der Labels ist ein Fehler aufgetreten.'

      return Promise.reject(error)
    }
  }

  isCurrentlyPrinting.value = false
}
</script>
