<template>
  <Teleport to="body">
    <SuiDimmer
      v-if="isPrompting || (!optional && !isGranted)"
      active
      page
    >
      <SuiHeader
        v-if="isPrompting"
        as="h2"
        icon
        inverted
      >
        <SuiHeaderContent>
          Zur Nutzung dieser Funktion bitte den Zugriff auf {{ objectNames }} erlauben
        </SuiHeaderContent>
      </SuiHeader>
      <div v-else-if="isDenied || isError">
        <SuiHeader
          as="h2"
          icon
          inverted
        >
          <SuiIcon
            name="frown outline"
          />
          <SuiHeaderContent v-if="isDenied">
            Der Zugriff auf {{ objectNamesDenied }} wurde verwert.
          </SuiHeaderContent>
          <SuiHeaderContent v-if="isError">
            Beim Zugriff auf {{ objectNamesError }} ist ein Fehler aufgetreten.
          </SuiHeaderContent>
          <SuiHeaderSubheader>
            <slot />
          </SuiHeaderSubheader>
        </SuiHeader>
        <div>
          <SuiButton @click="() => reload()">
            Erneut versuchen
          </SuiButton>
        </div>
      </div>
      <SuiLoader v-else />
    </SuiDimmer>
  </Teleport>
</template>
<script setup>
import {
  SuiIcon,
  SuiDimmer,
  SuiHeader,
  SuiButton,
  SuiLoader,
  SuiHeaderContent,
  SuiHeaderSubheader
} from 'vue-fomantic-ui'
import { onMounted, computed, ref } from 'vue'

const emit = defineEmits(['change', 'granted', 'denied', 'error', 'requested'])
const currentPermissions = ref({})
const isPrompting = ref(false)
const isGranted = ref(false)
const isDenied = ref(false)
const isError = ref(false)

const props = defineProps({
  permissions: {
    type: Object,
    required: true
  },
  optional: {
    type: Boolean,
    required: false,
    default: false
  },
  request: {
    type: Boolean,
    required: false,
    default: true
  },
  retryable: {
    type: Array,
    required: false,
    default: () => []
  }
})

function capitalizeFirstLetter (val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1)
}

const reload = async () => {
  for (const [key, value] of Object.entries(currentPermissions.value)) {
    if ((value === 'error' || value === 'denied') && props.retryable.indexOf(key) === -1) {
      window.location.reload()
      return
    }
  }

  await executeOrderly(async (type) => {
    const state = currentPermissions.value[type]
    if ((state === 'error' || state === 'denied') && props.retryable.indexOf(type) !== -1) {
      await requestPermission(type)
    }
  })

  emit('requested', getPermissions())
}

const objectNames = computed(() => getObjectsNames())

const objectNamesDenied = computed(() => getObjectsNames('denied'))

const objectNamesError = computed(() => getObjectsNames('error'))

const getObjectsNames = (stateFilter = null) => {
  const names = []

  for (const [type, state] of Object.entries(currentPermissions.value)) {
    if (stateFilter === null || state === stateFilter) {
      names.push(getDisplayName(type))
    }
  }

  return names.join(', ').replace(/,\s+([^,\s]*)$/, ' und $1')
}

const getDisplayName = (type) => {
  switch (type) {
    case 'camera':
      return 'die Kamera'
    case 'microphone':
      return 'das Mikrofon'
    case 'printer':
      return 'den Drucker'
    default:
      return capitalizeFirstLetter(type)
  }
}

const getPermissions = () => {
  return JSON.parse(JSON.stringify(currentPermissions.value))
}

const changed = () => {
  const data = getPermissions()
  let prompting = Object.values(data).length > 0
  let granted = Object.values(data).length > 0
  let denied = false
  let error = false

  Object.values(data).forEach((value) => {
    error = error || value === 'error'
    denied = denied || value === 'denied'
    prompting = prompting && value === 'prompt'
    granted = granted && value === 'granted'
  })

  isGranted.value = granted
  isError.value = error
  isPrompting.value = prompting
  isDenied.value = denied

  emit('change', data)
  if (granted) {
    emit('granted', data)
  } else if (denied) {
    emit('denied', data)
  } else if (error) {
    emit('error', data)
  }
}

const setState = (key, value) => {
  if (currentPermissions.value[key] !== value) {
    currentPermissions.value[key] = value
    changed()
  }
}

const handlers = {}

handlers.microphone = handlers.camera = () => {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices.getUserMedia({
      audio: 'microphone' in props.permissions,
      video: 'camera' in props.permissions
    }).then(() => {
      resolve('granted')
    }).catch((e) => {
      let name = e
      if (e instanceof DOMException) {
        name = e.name
      }

      if (name.indexOf('NotAllowedError') !== -1) {
        resolve('denied')
      } else {
        reject(name)
      }
    })
  })
}

const requestPermission = (type) => {
  let handlerFunction
  if (props.permissions[type] instanceof Function) {
    handlerFunction = props.permissions[type]
  } else {
    handlerFunction = handlers[type]
  }

  if (typeof handlerFunction === 'undefined') {
    return
  }

  setState(type, 'unknown')
  handlerFunction().then((state) => {
    if (state === undefined) {
      state = 'granted'
    }
    setState(type, state)
  }).catch(() => {
    setState(type, 'error')
  })
}

const registerCheck = (type) => {
  setState(type, 'unknown')
  return new Promise((resolve) => {
    if (props.permissions[type] instanceof Function) {
      requestPermission(type)
    } else {
      navigator.permissions.query({
        name: type
      }).then((status) => {
        status.onchange = () => {
          setState(type, status.state)
          if (status.state === 'granted' || status.state === 'error' || status.state === 'denied') {
            resolve()
          }
        }
        setState(type, status.state)

        if (props.request && status.state !== 'granted') {
          requestPermission(type)
        }

        if (status.state === 'granted' || status.state === 'error' || status.state === 'denied') {
          resolve()
        }
      }).catch((e) => {
        setState(type, 'error')
        resolve()
      })
    }
  })
}

const executeOrderly = async (toBeExecutedFunction) => {
  const requests = []

  for (const [type, callback] of Object.entries(props.permissions)) {
    if (callback === true) {
      requests.push(toBeExecutedFunction(type))
    }
  }

  await Promise.allSettled(requests)

  for (const [type, callback] of Object.entries(props.permissions)) {
    if (callback instanceof Function) {
      await toBeExecutedFunction(type)
    }
  }
}

onMounted(async () => {
  for (const type of Object.keys(props.permissions)) {
    setState(type, 'unknown')
  }

  await executeOrderly(registerCheck)
  emit('requested', getPermissions())
})
</script>
