import { Feature } from 'ol'

export default class FeatureAutoHandler {
  constructor (builder) {
    this.builder = builder
  }

  supports (data) {
    return data instanceof Feature
  }

  handle (data, id = null) {
    this.builder.withFeature(data, id)
  }
}
