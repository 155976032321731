<template>
  <div class="c-task-history">
    <h2 v-if="header.length > 0">
      {{ header }}
    </h2>
    <AccordionTable
      :headers="Object.values(headers)"
      :items="tasks"
      :menu="menu"
      :collapsed="collapsed"
    >
      <template #menu_middle>
        <TopicTreeSelect
          label=""
          event
          @changed="(topics) => updateFilter({topics})"
        />
      </template>
      <template #row="props">
        <td
          v-if="'id' in headers"
          class="noWrap"
          :data-label="headers.id"
        >
          <EntityLink
            :attr="{href: props.item.href}"
            label="Aufgabe"
            :reference="props.item.id"
          />
          <SuiIcon
            v-if="props.item.icon"
            v-bind="props.item.icon"
          />
        </td>
        <td
          v-if="'createdAt' in headers"
          class="noWrap"
          :data-label="headers.createdAt"
        >
          {{ props.item.createdAt }}
        </td>
        <td
          v-if="'reference' in headers"
          :data-label="headers.reference"
        >
          <EntityLink
            v-if="typeof props.item.reference != 'string'"
            v-bind="props.item.reference"
          />
          <span v-if="typeof props.item.reference == 'string'">{{ props.item.reference }}</span>
        </td>
        <td
          v-if="'topic' in headers"
          :data-label="headers.topic"
        >
          {{ props.item.topic }}
          <span class="ui small grey text">{{ props.item.subtopic }}</span>
        </td>
        <td
          v-if="'author' in headers"
          :data-label="headers.author"
        >
          <UserIndicator
            :name="fallbackUsername(props.item.author)"
          />
        </td>
        <td
          v-if="'deadline' in headers"
          :data-label="headers.deadline"
        >
          {{ props.item.deadline }}
        </td>
        <td
          v-if="'assignee' in headers"
          :data-label="headers.assignee"
        >
          <UserIndicator
            v-if="props.item.assigneeType === 'user' && props.item.assignee !== '-'"
            :name="fallbackUsername(props.item.assignee)"
          />
          <span v-else>{{ fallbackUsername(props.item.assignee) }}</span>
        </td>
        <td
          v-if="'status' in headers"
          :data-label="headers.status"
        >
          {{ props.item.status }}
        </td>
      </template>
      <template #detail="props">
        <SuiFeed
          connected
          class="c-label-left"
        >
          <SuiFeedEvent>
            <template #label>
              <UserIndicator
                :name="fallbackUsername(props.item.author)"
                icon
              />
            </template>
            <template #summary>
              <span class="ui user grey text">
                {{ fallbackUsername(props.item.author) }}
              </span> kommentierte
              <div class="date floated left">
                {{ props.item.createdAt }}
              </div>
            </template>
            <template #extraText>
              <TextParagraphs :text="props.item.comment" />
            </template>
          </SuiFeedEvent>
          <SuiFeedEvent
            v-for="(comment, index) in props.item.additionalComments"
            :key="index"
          >
            <template #label>
              <UserIndicator
                :name="fallbackUsername(comment.author)"
                icon
              />
            </template>
            <template #summary>
              <span class="ui user grey text">
                {{ fallbackUsername(comment.author) }}
              </span> kommentierte
              <div class="date floated left">
                {{ comment.created }}
              </div>
            </template>
            <template #extraText>
              <TextParagraphs
                :text="comment.comment"
                linkified
              />
            </template>
          </SuiFeedEvent>
        </SuiFeed>
      </template>
    </AccordionTable>
  </div>
</template>
<script>
import AccordionTable from '@/stories/molecule/AccordionTable.ce.vue'
import EntityLink from '@/stories/molecule/EntityLink.ce.vue'
import UserIndicator from '@/components/_partials/Atom/UserIndicator.ce.vue'
import TextParagraphs from '@/components/_partials/TextParagraphs.vue'
import TopicTreeSelect from '@/components/History/TopicTreeSelect.vue'
import { SuiIcon, SuiFeed, SuiFeedEvent } from 'vue-fomantic-ui'
import { request, url } from '@/js/request.js'

export default {
  components: {
    TopicTreeSelect,
    TextParagraphs,
    UserIndicator,
    SuiIcon,
    EntityLink,
    AccordionTable,
    SuiFeed,
    SuiFeedEvent
  },
  tag: 'task-history',
  props: {
    api: {
      type: String,
      default: null
    },
    collapsed: {
      type: Boolean,
      default: true
    },
    header: {
      type: String,
      default: ''
    },
    menu: {
      type: Array,
      default: () => []
    },
    show: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    tasks: null,
    filter: null
  }),
  computed: {
    headers () {
      const cols = {}

      const show = {
        ...{
          id: true,
          createdAt: true,
          reference: true,
          topic: true,
          author: true,
          assignee: true,
          status: true
        },
        ...this.show
      }

      if (show.id) {
        cols.id = '#'
      }

      if (show.createdAt) {
        cols.createdAt = 'Zeitpunkt'
      }

      if (show.reference) {
        cols.reference = 'Referenz'
      }

      if (show.topic) {
        cols.topic = 'Thema'
      }

      if (show.author) {
        cols.author = 'Ersteller'
      }

      if (show.deadline) {
        cols.deadline = 'Termin'
      }

      if (show.assignee) {
        cols.assignee = 'An'
      }

      if (show.status) {
        cols.status = 'Status'
      }

      return cols
    }
  },
  mounted () {
    this.updateFilter({})
  },
  methods: {
    updateFilter (filter) {
      const updated = { ...this.filter, ...filter }

      if (JSON.stringify(updated) === JSON.stringify(this.filter)) {
        return
      }

      this.filter = updated

      this.tasks = null
      request('get', url(this.api, this.filter), tasks => {
        this.tasks = tasks
      })
    },
    fallbackUsername (username) {
      return typeof username === 'string' && username.length > 0 ? username : 'System'
    }
  }
}
</script>
