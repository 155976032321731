<template>
  <SuiModal
    v-model="model"
  >
    <SuiModalHeader>
      <span>
        {{ headerLabel }}
      </span>
    </SuiModalHeader>
    <SuiModalContent class="ModalMessage-content">
      <SuiModalDescription>
        <slot name="content" />
      </SuiModalDescription>
    </SuiModalContent>
    <SuiModalActions class="ModalMessage-actions">
      <SuiButton
        positiv
        class="orange"
        @click="model = false"
      >
        Abbrechen
      </SuiButton>
    </SuiModalActions>
  </SuiModal>
</template>

<script setup>
import {
  SuiModal,
  SuiModalContent,
  SuiModalHeader,
  SuiModalActions,
  SuiModalDescription,
  SuiButton
} from 'vue-fomantic-ui'

defineProps({
  headerLabel: {
    type: String,
    default: ''
  }
})
const model = defineModel({
  type: Boolean,
  default: false
})
</script>
