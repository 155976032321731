<template>
  <SuiForm>
    <p>Bitte Lageinformationen eingeben</p>
    <SuiFormField
      v-model="location"
      label="Lage der Wohneinheit im Gebäude"
      placeholder="Lage"
    />
    <div class="ui grid">
      <div class="eight wide column">
        <h4 class="ui header">
          Anschluss Datennetz
        </h4>
        <SuiFormField>
          <SuiCheckbox
            v-model="data"
            label="GF-TA"
            value="gfTa"
          />
        </SuiFormField>
        <SuiFormField>
          <SuiCheckbox
            v-model="data"
            label="FTU"
            value="ftu"
          />
        </SuiFormField>
        <SuiFormField>
          <SuiCheckbox
            v-model="data"
            label="MK"
            value="mk"
          />
        </SuiFormField>
        <SuiFormField>
          <SuiCheckbox
            v-model="data"
            label="Ethernet"
            value="ethernet"
          />
        </SuiFormField>
      </div>
      <div class="eight wide column">
        <h4 class="ui header">
          Anschluss TV
        </h4>
        <SuiFormField>
          <SuiCheckbox
            label="Eigene Faser bis in die WE"
            value="fiber"
            radio
            :model-value="tvNetwork"
            @update:model-value="tvNetwork = ['fiber']"
          />
        </SuiFormField>
        <SuiFormField>
          <SuiCheckbox
            label="Koax (Details siehe Gebäude)"
            value="koax"
            radio
            :model-value="tvNetwork"
            @update:model-value="tvNetwork = ['koax']"
          />
        </SuiFormField>
        <SuiFormField>
          <SuiCheckbox
            label="Keine physikalische Anbindung"
            value="none"
            radio
            :model-value="tvNetwork"
            @update:model-value="tvNetwork = ['none']"
          />
        </SuiFormField>
      </div>
    </div>
    <SuiFormField
      v-if="mac === null && data.includes('mk')"
      label="ONT-Mac"
      :model-value="newMac"
      @update:model-value="updateMac"
    />
  </SuiForm>
</template>

<script setup>
import {
  SuiCheckbox,
  SuiForm,
  SuiFormField
} from 'vue-fomantic-ui'
import { ref, watch } from 'vue'
import * as R from 'ramda'
import { selectDataNetwork } from '@/js/utils/unit-form.js'

const data = defineModel('data', { type: Array, default: [] })
const tv = defineModel('tv', { type: String })
const location = defineModel('location', { type: String, default: '' })

defineProps({
  mac: {
    type: String,
    default: null
  }
})

const tvNetwork = ref(R.isEmpty(tv.value) ? [] : [tv.value])
const newMac = ref(null)

const emit = defineEmits(['mac'])

watch(() => tvNetwork.value, function (newValue) {
  tv.value = R.head(newValue) ?? null
})

watch(() => data.value, function (newValue, oldValue) {
  const filteredValue = selectDataNetwork(newValue, oldValue)

  if (!R.equals(filteredValue, newValue)) {
    data.value = filteredValue
  }
})

function updateMac (value) {
  if (R.isEmpty(value)) {
    emit('mac', null)
  } else {
    emit('mac', value)
  }
}
</script>
